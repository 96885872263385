.FilterSelection {
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    display: inline-block;
    margin-left: 7px;
    margin-right: 7px;
    border-radius: 15px;
    padding: 4px;
    border: 2px solid lavender;
    color: gray;
    font-weight: 300;
    cursor: pointer;
    user-select: none;
}

.FilterSelectionChecked {
    background-color: lavender;
    color: black;
}