.App {
  text-align: center;
}

.title {
  margin-top: 25%;
  text-align: center;
  width: 100%;
}

.top-title {
  margin-top: 2%;
}

.title-text-mobile {
  font-size: 40px;
  line-height: 60px;
}

.title-text {
  font-size: 72px;
  line-height: 122px;
}

.top-text-mobile {
  font-size: 24px;
}

.top-text {
  font-size: 32px;
  transition: background-color 0.5s;
}

.top-text:hover {
  background-color: white;
  cursor: pointer;
  transition: background-color 0.5s;
}

.top-text-mobile:hover {
  background-color: white;
  cursor: pointer;
  transition: background-color 0.5s;
}

.tips {
  font-style: italic;
  background-color: #e6e6fa;
  font-size: 16px;
  font-weight: bold;  
  margin-top: 4px;
  
}
