hr {
  border: 1px solid lavender;
}

.QueriedLyrics {
  background-color: rgba(255, 255, 255, 0.93);
  width: 60%;
  height: calc(66% + 30px);
  position: absolute;
  overflow: auto;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: calc(6.5% + 40px);
}

.QueriedLyrics-mobile {
  background-color: rgba(255, 255, 255, 0.93);
  width: 80%;
  height: calc(70% + 30px);
  position: absolute;
  overflow: auto;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: calc(18% + 34px);
  height: calc(73% + 24px);
}

.totalResults {
  width: 100%;
  color: white;
  font-weight: 500;
  text-align: center;
  position: absolute;
  margin-top: calc(4.5% + 40px);
}

.totalResults-mobile {
  width: 100%;
  font-weight: 500;
  color: white;
  text-align: center;
  position: absolute;
  margin-top: calc(13% + 34px);
}

.loading {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border: 5px solid lavender; /* Light grey */
  border-top: 5px solid white; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
